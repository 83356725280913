document.addEventListener("DOMContentLoaded", () => {
  let timer = null;
  let hideTimeout = null;

  const searchLiveResults = document.getElementById("search-live-results");
  const searchQuery = document.getElementById("search-query");

  function searchLive(query) {
    timer = null;
    fetch("/search/live/?query=" + query)
      .then(response => response.text())
      .then(html => {
        searchLiveResults.innerHTML = html;
      });
  }

  if (!searchQuery) return
  searchQuery.addEventListener("input", evt => {
    const query = evt.target.value;
    if (query.length > 0) {
      searchLiveResults.style.display = "block";
    } else {
      searchLiveResults.style.display = "none";
    }
    searchLiveResults.innerHTML = `<div class="p-3">Searching for ${query}...</div>`;
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function() {
      searchLive(query);
    }, 1000);
  });

  searchLiveResults.addEventListener("mouseleave", evt => {
    hideTimeout = setTimeout(() => {
      evt.target.style.display = "none";
      hideTimeout = null;
    }, 1000);
  });

  searchLiveResults.addEventListener("mouseenter", evt => {
    if (hideTimeout) clearTimeout(hideTimeout);
  });
});

document.addEventListener("DOMContentLoaded", () => {
  searchResults = document.querySelector('.search-results')
  if (!searchResults) return
  searchResults.addEventListener('click', e => {
    let linkAncestor = e.target.closest('a')
    if (linkAncestor) {
      linkAncestor.classList.add('seen')
    }
  })

  let queryParams = new URLSearchParams(window.location.search)
  let docViewUrl = queryParams.get('open')
  if (docViewUrl) {
    let docViewLink = document.querySelector(`a[href*='${docViewUrl}']`)
    docViewLink && docViewLink.classList.add('seen')
    let docViewFrame = document.querySelector('iframe[name=doc_view_frame]')
    docViewFrame.src = docViewUrl
    showModal()
  }
})
